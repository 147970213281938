
.admin {
    font-family: "Open Sans",Helvetica,Arial,sans-serif;
    font-size: 14px;
}

.admin h2 {
    color: #1E90FF;
}

.section {
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.3);
}