$primary: #DE3163;
$secondary: #999999;
@import "bootstrap";

* {
	margin: 0;
	padding: 0;
	font: inherit;
}
html {
	height: 100%;
}
body {
	//font-family: Roboto, sans-serif;
	font-family: Myriad Pro, sans-serif;
	color: $secondary;
	height: 100%;
}


@media only screen and (min-width: 800px) {
	.wrapper {
		width: 1000px;
		margin-left: auto;
		margin-right: auto;
		padding: 0 10px 0 10px;
	}
}

@media only screen and (max-width: 800px) {
	.wrapper {
		width: 98%;
		margin-left: auto;
		margin-right: auto;
	}
}

.content {
    @extend .mx-auto;
    @extend .text-center;
	background-color: #5E5E5E;
	border: 1px solid #000000;
	color: #FFFFFF;
	box-shadow: 2px 4px 6px 2px #000000;
}

.content h1 {
    margin: 10px 0 10px 0;
    background-color: #5E5E5E;
	font-family: 'pacifica', sans-serif;
    text-shadow: 2px 2px #000000;
}

.btn, .input {
	border: 1px solid $primary;
	border-radius: 20px;
	color: $primary;
	text-align: center;
	padding: 6px 12px;
	margin: 5px;
}
.btn-primary {
	color: #FFFFFF;
	padding: 0px;
	min-width: 250px;
}
.btn-primary:hover {
	background-color: #000000;
}

.btn-secondary {
	color: #ffffff;
	background-color: #868686;
	border: 1px solid #CCCCCC;
	padding: 0px;
	min-width: 250px;
	letter-spacing: 0.2em;
}

.btn-secondary:hover {
	color: #000000 !important;
}

.btn:hover {
	color: $primary;
	box-shadow: 0 2px 5px 0 rgba(0,0,0, 0.32), 0 2px 10px 0 rgba(0, 0,0, 0.24);
}

.btn-fa {
	border: none;
	padding: 0px;
}

.btn-fa:focus {
	outline: none;
	border: none;
	box-shadow: none;
}
.btn-fa:hover {
	outline: none;
	border: none;
	box-shadow: none;
}

.btn-small {
	font-size: smaller;
	margin:0px;
}
.btn i {
	padding-left: 5px;
}
.input {
	margin: 5px;
}
.input::placeholder {
	color: rgba($primary, 0.79);
}

.input:focus {
	outline: none !important;
	border: 1px solid $primary;
	box-shadow: 0 2px 5px 0 rgba(0,0,0, 0.32), 0 2px 10px 0 rgba(0, 0,0, 0.24);
	color: $primary;
}

.list-group {
	border-radius: 20px 0px 0px 20px;
}

.dropdown-menu {
	border-radius: 20px 20px 20px 20px;
}

.list-group, .dropdown-menu {
	color: $primary;
	max-height: 350px;
	overflow-y: auto;
	text-align: left;
}

.dropdown-item, .list-group-item {
	color :$primary;
}

.list-group-item p {
	margin: 0px;
	font-size: smaller;
	word-wrap: break-word;
}
.dropdown-item:hover, .list-group-item:hover {
	text-decoration: none;
	cursor: pointer;
	color :$primary;
	background-color: #e9ecef;
}

.btn.dropdown-toggle {
	border-radius: 20px 0px 0px 20px;
}
.dropdown-input {
	border-radius: 0px 20px 20px 0px;
}

.modal-backdrop {
	z-index: -1;
  }
  
.hidden-menu {
	position: fixed;
	text-align: center;
	gap: 60px;
	left: -100%;
	width: 100%;
	transition: 0.3s;
	list-style: none;
	background-color: #FFFFFF;
	margin-top: 10px;
}

#search-container {
	top:70px;
	z-index: 1;
	width: 100%;
}

.social {
	position: fixed;
	width: 100px;
	left: 0px;
	top: 70%;
	-webkit-transform: translateY(-70%);
	-ms-transform: translateY(-70%);
	transform: translateY(-70%);
}
.button-thumbnail {
	margin: 5px;
}

.modal-open .container-fluid, .modal-open  .container {
    -webkit-filter: blur(5px) grayscale(90%);
}

// car.js

.car-container {
    @extend .col-md-11;
    @extend .col-lg-10;
    @extend .col-xl-9;
    @extend .mx-auto;
    @extend .pt-3;
}
.car-container .car-photos {
    @extend .pt-3;
    @extend .pb-3;
    @extend .mb-3;
    @extend .mt-3;
    border-radius: 5px;
    background: rgb(199,224,255);
}
.car-container img.thumbnail {
    @extend .rounded;
    margin: 5px;
    border: 1px solid rgba($primary, 0.3);
	box-shadow: 0 2px 5px 0 rgba(0,0,0, 0.32), 0 2px 10px 0 rgba(0, 0,0, 0.24);
}
.car-container img.thumbnail:hover, .car-container img.selected {
    border: 1px solid rgba($primary, 0.9);
    cursor: pointer;
}
.car-container .car-description {
    @extend .pt-3;
    @extend .mt-3;
    @extend .rounded;
    border: 1px solid $primary;
    background-color: rgba($primary, 0.1);
}

.search-menu.active, .sort-menu.active {
	left: 0;
}

.search-menu .row, .sort-menu .row {
	border-top: 2px solid $primary;
	margin: 10px 0px 10px 0px;
}

.search-menu ul {
	list-style-type: none;
	padding: 0;
}

.filter-btn {
	font-size: 12px;
	background-color: #5E5E5E;
	margin: 7px;
	border-radius: 10px;
	color: #FFFFFF;
}

.filter-btn:hover {
	transition: 0.3s;
	color: $primary;
}

.filter-btn.active {
	color: $primary;
}

.filter {
	display: none;
}

.filter.active {
	display: flex;
}


// Social.js
.button-thumbnail {
    height: 50px;
    width: 50px;
    margin: 15px;
  }

.border-1 {
    border-width:1px !important;
}
.border-2 {
    border-width:2px !important;
}
.border-3 {
    border-width:3px !important;
}
.border-4 {
    border-width:4px !important;
}
.border-5 {
    border-width:5px !important;
}
.img-thumb {
	@extend .rounded;
	width: 150px;
	margin-left: 5px;
}
.img-thumb-list-4 {
	@extend .rounded;
	object-fit: contain;
	margin: 2px;
	width: 23%;
}
.img-thumb-list-3 {
	@extend .rounded;
	object-fit: contain;
	margin: 2px;
	width: 30%;
}

